/*------------- #BLOCKS --------------*/


@import "theme-styles/variables";

@import "blocks/heading";
@import "blocks/buttons";
@import "blocks/socials";
@import "blocks/alerts-messages";
@import "blocks/pie-charts";
@import "blocks/skills";
@import "blocks/accordion";
@import "blocks/tables";
@import "blocks/testimonials";
@import "blocks/sliders";
@import "blocks/quotes";
@import "blocks/info-boxes";
@import "blocks/counters";
@import "blocks/countdown";
@import "blocks/events";
@import "blocks/forms";
@import "blocks/radio-btn";
@import "blocks/checkboxes";
@import "blocks/lessons";
@import "blocks/move-background";
@import "blocks/pricing-tables";
@import "blocks/contacts";
@import "blocks/experts";
@import "blocks/popup-windows";
@import "blocks/author-details";
@import "blocks/blogs";
@import "blocks/navigation";
@import "blocks/comments";
@import "blocks/featured-block";
@import "blocks/clients";
@import "blocks/crypto-converter";
@import "blocks/circled-block";
@import "blocks/chart-statistics";
@import "blocks/range-slider";