/*------------- #BUTTONS --------------*/


.btn {
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  padding: 5px 10px;
  font-size: 18px;
  font-weight: $weight-black;
  border-radius: 50px;
  position: relative;
  transition: all .3s ease;
  text-align: center;
  line-height: 1;
  color: $white-color;
  border: 4px solid;

  & + .btn {
	margin-left: 30px;
  }

  &:hover {
    background-color: transparent;
  }

  &:active {
    background-color: $primary-color;
    border-color: $primary-color;
  }
}

.btn--x-large {
  font-size: 22px;
  border-radius: 33px;
  padding: 18px 36px;
}

.btn--large {
  font-size: 18px;
  border-radius: 30px;
  padding: 17px 34px;
}

.btn--medium {
  font-size: 16px;
  border-radius: 25px;
  padding: 13px 26px;
}

.btn--small {
  font-size: 16px;
  border-radius: 20px;
  padding: 9px 20px;
  border-width: 3px;
}

.btn--mini {
  font-size: 14px;
  border-radius: 15px;
  padding: 5px 10px;
  border-width: 3px;
}

.woox-icon {
  transition: all .3s ease;
}

.double-arrow {
  position: relative;
  transition: all .3s ease;

  .woox-icon {
    width: 17px;
    height: 17px;

    &:last-child {
      position: absolute;
      top: 1px;
      right: -3px;
    }
  }
}



/*------------- #Color-scheme for buttons --------------*/

.btn--secondary {
  border-color: $white-color;
  background-color: $white-color;
  color: $border-color;

  .woox-icon {
    fill: $border-color;
  }

  &:hover {
    color: $white-color;

    .woox-icon {
      fill: $white-color;
    }
  }

  &:active {
    color: $white-color;
    border-color: $link-color;
    background-color: $link-color;

    .woox-icon {
      fill: $white-color;
    }
  }
}

.btn--primary {
  border-color: $primary-color;
  background-color: $primary-color;
  color: $white-color;

  .woox-icon {
    fill: $white-color;
  }

  &:hover {
    background-color: transparent;
    color: $primary-color;

    .woox-icon {
      fill: $primary-color;
    }
  }

  &:active {
    color: $black-lighter;
    border-color: darken($primary-color, 10%);
    background-color: darken($primary-color, 10%);

    .woox-icon {
      fill: $black-lighter;
    }
  }
}

.btn--green-light {
  border-color: $green-light;
  background-color: $green-light;
  color: $white-color;

  .woox-icon {
    fill: $white-color;
  }

  &:hover {
    color: $green-light;

    .woox-icon {
      fill: $green-light;
    }
  }

  &:active {
    color: $black-lighter;
    border-color: darken($green-light, 10%);
    background-color: darken($green-light, 10%);

    .woox-icon {
      fill: $black-lighter;
    }
  }
}

.btn--dark {
  border-color: $dark;
  background-color: $dark;
  color: $icon-color;

  .woox-icon {
    fill: $icon-color;
  }

  &:hover {
    color: $white-color;

    .woox-icon {
      fill: $white-color;
    }
  }

  &:active {
    color: $border-color;
    border-color: darken($dark, 10%);
    background-color: darken($dark, 10%);

    .woox-icon {
      fill: $border-color;
    }
  }
}

.btn--dark-lighter {
  border-color: $dark-lighter;
  background-color: $dark-lighter;
  color: $icon-color;

  .woox-icon {
    fill: $icon-color;
  }

  &:hover {
	color: $white-color;

    .woox-icon {
      fill: $white-color;
    }
  }

  &:active {
    color: $icon-color;
    border-color: darken($dark-lighter, 10%);
    background-color: darken($dark-lighter, 10%);

    .woox-icon {
      fill: $icon-color;
    }
  }
}

.btn--blue-light {
  border-color: $blue-light;
  background-color: $blue-light;
  color: $white-color;

  .woox-icon {
    fill: $white-color;
  }

  &:hover {
    color: $blue-light;

    .woox-icon {
      fill: $blue-light;
    }
  }

  &:active {
    color: $black-lighter;
    border-color: darken($blue-light, 10%);
    background-color: darken($blue-light, 10%);

    .woox-icon {
      fill: $black-lighter;
    }
  }
}

.btn--transparent {
  background-color: transparent;

  &.btn--secondary {
    color: $white-color;

    .woox-icon {
      fill: $white-color;
    }

    &:hover {
      color: $primary-color;
      border-color: $primary-color;

      .woox-icon {
        fill: $primary-color;
      }
    }

    &:active {
      background-color: $primary-color;
      color: $white-color;
      border-color: $primary-color;

      .woox-icon {
        fill: $white-color;
      }
    }
  }

  &.btn--primary {
    color: $primary-color;

    .woox-icon {
      fill: $primary-color;
    }

    &:hover {
      color: $white-color;
      background-color: $primary-color;

      .woox-icon {
        fill: $white-color;
      }
    }

    &:active {
      background-color: darken($primary-color, 10%);
      border-color: darken($primary-color, 10%);
      color: $black-lighter;

      .woox-icon {
        fill: $black-lighter;
      }
    }
  }

  &.btn--green-light {
    color: $green-light;

    .woox-icon {
      fill: $green-light;
    }

    &:hover {
      background-color: $green-light;
      color: $white-color;

      .woox-icon {
        fill: $white-color;
      }
    }

    &:active {
      background-color: darken($green-light, 10%);
      border-color: darken($green-light, 10%);
      color: $black-lighter;

      .woox-icon {
        fill: $black-lighter;
      }
    }
  }

  &.btn--blue-light {
    color: $blue-light;

    .woox-icon {
      fill: $blue-light;
    }

    &:hover {
      background-color: $blue-light;
      color: $white-color;

      .woox-icon {
        fill: $white-color;
      }
    }

    &:active {
      background-color: darken($blue-light, 10%);
      border-color: darken($blue-light, 10%);
      color: $black-lighter;

      .woox-icon {
        fill: $black-lighter;
      }
    }
  }

  &.btn--dark-lighter {
    color: $icon-color;

    .woox-icon {
      fill: $icon-color;
    }

    &:hover {
      background-color: $dark-lighter;
      color: $white-color;

      .woox-icon {
        fill: $white-color;
      }
    }

    &:active {
      background-color: darken($dark-lighter, 10%);
      border-color: darken($dark-lighter, 10%);
      color: $border-color;

      .woox-icon {
        fill: $border-color;
      }
    }
  }

  &.btn--dark {
	color: $icon-color;

    .woox-icon {
      fill: $icon-color;
    }

	&:hover {
	  background-color: $dark;
	  color: $white-color;

      .woox-icon {
        fill: $white-color;
      }
	}

	&:active {
      background-color: darken($dark, 10%);
      border-color: darken($dark, 10%);
	  color: $border-color;

      .woox-icon {
        fill: $border-color;
      }
	}
  }
}

.btn--with-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .woox-icon, i {
	width: 14px;
	height: 14px;
    font-size: 14px;
	transition: all .3s ease;
	position: relative;
  }

  &.btn--x-large {
    .woox-icon, i {
      width: 22px;
      height: 22px;
      font-size: 22px;
    }
  }
}

.btn--icon-right {
  .woox-icon, i {
    margin-left: 10px;
  }
}

.btn--icon-left {
  .woox-icon, i {
    margin-right: 10px;
  }
}

.btn-market-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.btn--market {
  padding: 12px 20px;
  margin-right: 30px;

  .woox-icon, i {
    min-width: 30px;
    min-height: 30px;
  }

  .text {
	display: inline-flex;
	flex-direction: column;
	text-align: left;
  }

  .sup-title {
	font-size: 12px;
    transition: all .3s ease;
  }

  .title {
	font-size: 16px;
  }

  & + .btn--market {
    margin-right: 0;
    margin-left: 0;
  }
}

.btn--apple {
  color: $link-color;

  .woox-icon {
    fill: $link-color;
  }

  .sup-title {
    color: $icon-color;
    transition: all .3s ease;
  }

  &:hover,
  &:active {
    background-color: $primary-color;
    border-color: $primary-color;
    color: $secondary-color;

    .woox-icon {
      fill: $secondary-color;
    }

    .sup-title {
      color: $secondary-color;
    }
  }
}

.btn--google {
  color: $secondary-color;
  background-color: $primary-color;
  border-color: $primary-color;

  .woox-icon {
    fill: $secondary-color;
  }

  .sup-title {
    color: $secondary-color;
    transition: all .3s ease;
  }

  &:hover,
  &:active {
    background-color: transparent;
    border-color: $link-color;
    color: $link-color;

    .sup-title {
      color: $link-color;
    }

    .woox-icon {
      fill: $link-color;
    }
  }
}

.btn--round {
  border-radius: 100%;
  padding: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  position: relative;

  &:hover {
    .woox-icon {
      fill: $primary-color;
      color: $primary-color;
    }
  }

  .woox-icon {
    height: 18px;
    width: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.btn--with-count {
  font-size: 16px;
  background-color: transparent;

  &:hover {
    .count {
      transform: scale(1.1);
      color: $dark;
    }
  }

  .count {
    display: block;
    border-radius: 100%;
    position: absolute;
    right: -4px;
    top: -4px;
    text-align: center;
    transition: all .3s ease;
  }

  &.btn--x-large {
    padding-right: 80px;

    .count {
      height: 66px;
      width: 66px;
      line-height: 66px;
    }
  }

  &.btn--large {
    padding: 18px 70px 18px 34px;

    .count {
      height: 60px;
      width: 60px;
      line-height: 60px;
    }
  }

  &.btn--medium {
    padding-right: 60px;

    .count {
      height: 50px;
      width: 50px;
      line-height: 50px;
    }
  }

  &.btn--small {
    padding-right: 50px;

    .count {
      height: 40px;
      width: 40px;
      line-height: 40px;
    }
  }

  &.btn--mini {
    padding-right: 30px;

    .count {
      height: 30px;
      width: 30px;
      line-height: 30px;
    }
  }

  &.btn--secondary {
    .count {
      background-color: $secondary-color;
    }
  }

  &.btn--primary {
    .count {
      background-color: $primary-color;
    }
  }

  &.btn--green-light {
    .count {
      background-color: $green-light;
    }
  }

  &.btn--blue-light {
    .count {
      background-color: $blue-light;
    }
  }

  &.btn--dark-lighter {
    .count {
      background-color: $dark-lighter;
    }
  }

  &.btn--dark {
    .count {
      background-color: $dark;
    }
  }
}

.btn--share {
  border-color: $body-font-color;

  &:active {
    background-color: transparent;
    border-color: $hr-color;
  }

  .woox-icon {
	fill: $body-font-color;
  }

  .socials {
    padding-left: 65px;
    padding-right: 10px;
	margin: 0;
	display: flex;
    transform: translateX(-100px);
    transition: transform .6s ease, opacity .3s ease;
    opacity: 0;

	li {
	  margin-right: 10px;
      transition: all .8s ease;
      transform: rotate(0deg);
      display: flex;
      align-items: center;

      a {
        display: inline-block;
      }
	}

	.woox-icon {
	  height: 30px;
	  width: 30px;
      border-radius: 100%;
      transform: none;
      top: auto;
      left: auto;

      &:hover {
		box-shadow: 0 5px 15px -5px rgba($primary-color, 1);
	  }
	}
  }

  .shared-wrap {
	display: flex;
	position: absolute;
	height: 60px;
    max-width: 60px;
	left: -4px;
	top: -4px;
	transition: max-width .8s ease, opacity .3s ease;
	border: 4px solid $body-font-color;
	border-radius: 33px;
	opacity: 0;
    visibility: hidden;
  }

  &.open {

	.shared-wrap {
      visibility: visible;
	  opacity: 1;
      max-width: 300px;
	}

	.socials {
      transform: translateX(0);
	  opacity: 1;

      li {
        transform: rotate(360deg);
      }
	}
  }
}

.btn--share-in-down {
  border-color: $body-font-color;

  &:active {
    background-color: transparent;
    border-color: $hr-color;
  }

  .woox-icon {
    fill: $body-font-color;
  }

  .socials {
    padding: 55px 10px 10px;
    margin: 0;
    display: flex;
    flex-direction: column;
    transform: translate(0, -100px);
    transition: transform .6s ease, opacity .3s ease;
    opacity: 0;

    li {
      margin-right: 0;
      margin-top: 10px;
      transition: all .8s ease;
      transform: rotate(0deg);
      display: flex;
      align-items: center;

      a {
        display: inline-block;
      }
    }

    .woox-icon {
      height: 30px;
      width: 30px;
      border-radius: 100%;
      transform: none;
      top: auto;
      left: auto;

      &:hover {
        box-shadow: 0 5px 15px -5px rgba($primary-color, 1);
      }
    }
  }

  .shared-wrap {
    display: flex;
    position: absolute;
    height: 60px;
    max-width: 60px;
    left: -4px;
    top: -4px;
    transition: max-width .8s ease, opacity .3s ease;
    border: 4px solid $body-font-color;
    border-radius: 33px;
    opacity: 0;
    visibility: hidden;
  }

  &.open {

    .shared-wrap {
      visibility: visible;
      opacity: 1;
      max-width: 60px;
      max-height: 300px;
      height: auto;
      width: 60px;
    }

    .socials {
      transform: translateX(0);
      opacity: 1;

      li {
        transform: rotate(360deg);
      }
    }
  }
}


/*================= Styles for links ============*/

.content-block--dark,
.form--dark {
  a {

    &:hover {
      color: $white-color;
    }

    &:active {
      color: $primary-color;
    }
  }
}

.link-underlined {
  text-decoration: underline;
}


/*------------- #Button back-to-top --------------*/

.back-to-top {
  position: fixed;
  z-index: 1000;
  width: 120px;
  height: 120px;
  display: block;
  fill: $white-color;
  stroke: inherit;
  transition: all .3s ease;
  bottom: -55px;
  left: 50%;
  transform: translate(-50%, 0);
  cursor: pointer;
  border-radius: 100%;
  background-color: $primary-color;
  text-align: center;
  box-shadow: inset 5px 5px 20px 0 rgba(255,133,0,.4);
  outline: none;

  &:after {
	content: '';
	display: block;
	height: 12px;
	width: 12px;
	border-radius: 100%;
	background-color: $primary-color;
	position: absolute;
	top: -4px;
	right: -4px;
	box-shadow: inset 5px 5px 20px 0 rgba(255,133,0,.4);
    transition: all .3s ease;
  }

  &.hidden {
	opacity: 0;
  }

  .woox-icon {
	fill: $white-color;
	height: 20px;
	width: 20px;
    margin-top: 30px;
  }

  &:hover {
    bottom: -50px;
    box-shadow: inset 5px 5px 20px 0 rgba(255,133,0,0), 5px 5px 20px 0 rgba(255,133,0,.4);

    &:after {
      box-shadow: inset 5px 5px 20px 0 rgba(255,133,0,0), 5px 5px 20px 0 rgba(255,133,0,.4);
    }
  }
}

.primary-link-with-icon {
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  color: $white-color;
  font-weight: 700;

  .woox-icon {
    width: 13px;
    height: 13px;
    margin-left: 15px;
    fill: currentColor;
  }

  &:hover {
    color: $primary-color;
  }
}

.universal-btn-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: -20px;

  .btn {
    margin-bottom: 20px;
    margin-right: 20px;

    & + .btn {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

/*------------- Responsive Mode --------------*/


@media (max-width: 799px) {
  .back-to-top {
    width: 60px;
    height: 60px;
    bottom: -30px;

    &:hover {
      bottom: -25px;
    }
  }

  .back-to-top .woox-icon {
    height: 15px;
    width: 15px;
    margin-top: 10px;
  }

  .btn--x-large {
    font-size: 18px;
    padding: 16px 32px;
  }

  .btn--large {
    font-size: 16px;
    padding: 15px 30px;
  }

  .btn--medium {
    font-size: 14px;
    padding: 12px 24px;
  }

  .btn--small {
    font-size: 12px;
    padding: 9px 20px;
  }
}

@media (max-width: 768px) {

  .btn--round {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-width: 2px;

    .woox-icon {
      height: 10px;
      width: 10px;
    }
  }

  .btn--market {
    padding: 8px 12px;
    margin-right: 5px;

    .woox-icon, i {
      min-width: 15px;
      min-height: 15px;
    }

    .sup-title {
      font-size: 10px;
    }

    .title {
      font-size: 12px;
    }
  }

  .btn--icon-right {
    .woox-icon, i {
      margin-left: 5px;
    }
  }

  .btn--icon-left {
    .woox-icon, i {
      margin-right: 5px;
    }
  }

  .btn--share {
    .socials {
      padding-left: 35px;

      li {
        .woox-icon {
          height: 20px;
          width: 20px;
        }
      }
    }

    .shared-wrap {
      height: 30px;
      max-width: 30px;
      left: -2px;
      top: -2px;
      border-width: 2px;
    }
  }

  .btn--share-in-down .shared-wrap {
    height: 30px;
    max-width: 30px;
    left: -2px;
    top: -2px;
    border-width: 2px;
  }

  .btn--share.btn--round {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-width: 2px;

    .woox-icon {
      height: 10px;
      width: 10px;
    }
  }

  .btn--share-in-down .socials .woox-icon {
    height: 20px;
    width: 20px;
  }

  .btn--share-in-down.open .shared-wrap {
    max-width: 30px;
    width: 30px;
  }

  .btn--share-in-down .socials {
    padding: 25px 3px 10px;
  }
}
