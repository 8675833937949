/*------------- #COUNTDOWN --------------*/


.crumina-countdown-item {
  text-align: center;

  & + .btn {
    margin-left: 40px;
  }
}

.crumina-countdown,
.crumina-countdown-number {
  min-height: 100px !important;
  width: 100% !important;
  max-width: 400px !important;
  margin: 0 auto;
  display: inline-flex;
  z-index: 10;
  position: relative;
}

.countdown--with-bg {
  padding: 80px;
  border-radius: 20px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;

  .overlay {
	opacity: 1;
  }
}

.countdown-bg1 {
  background-image: url("../img/countdown-bg1.png");
  background-color: #111216;
}

.countdown-bg2 {
  background-image: url("../img/countdown-bg2.jpg");
}

.countdown-bg3 {
  background-image: url("../img/countdown-bg3.jpg");
}

.crumina-countdown-number {
  .time_circles > div > span {
    color: $primary-color;
    display: inline-block;
    width: auto;
  }

  .time_circles > div > h4 {
    bottom: -30px;
  }

  .time_circles > div {

    &:after {
      content: ':';
      display: block;
      font-size: 2rem;
      line-height: 1;
      color: $white-color;
      position: absolute;
      right: -5px;
      bottom: 5px;
    }

    &:last-child:after {
      display: none;
    }
  }
}

/*================= Responsive Mode ============*/


@media (max-width: 991px) {

  .countdown--with-bg {
    padding: 40px;
  }
}

@media (max-width: 768px) {
  .crumina-countdown-item .time_circles > div > h4 {
    bottom: -30px;
    font-size: 10px !important;
  }
}
