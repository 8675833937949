/*------------- #STYLES for checkboxes --------------*/

.checkbox {
  margin-bottom: 1rem;
}
.checkbox label {
  cursor: pointer;
  padding-left: 0;
  margin: 0;
  font-weight: $weight-black;
  color: $icon-color;
  font-size: 14px;

  span {
	margin-left: 0;
  }
}
.checkbox input[type=checkbox] {
  opacity: 0;
  position: absolute;
  margin: 0;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  left: 0;
  pointer-events: none;
}
.checkbox .checkbox-material {
  vertical-align: middle;
  position: relative;
  top: 1px;
  padding-right: 20px;
  display: inline-block;
}
.checkbox .checkbox-material:before {
  display: block;
  position: absolute;
  left: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.84);
  height: 20px;
  width: 20px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
  top: 0;
  -webkit-transform: scale3d(2.3,2.3,1);
  -moz-transform: scale3d(2.3,2.3,1);
  -o-transform: scale3d(2.3,2.3,1);
  -ms-transform: scale3d(2.3,2.3,1);
  transform: scale3d(2.3,2.3,1);
}
.checkbox .checkbox-material .check {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid $border-color;
  background-color: $black-lighter;
  overflow: hidden;
  z-index: 1;
  border-radius: 5px;
}
.checkbox .checkbox-material .check:before {
  position: absolute;
  content: "";
  transform: rotate(45deg);
  display: block;
  margin-top: -3px;
  margin-left: 7px;
  width: 0;
  height: 0;
  box-shadow: 0 0 0 0 inset;
  -webkit-animation: checkbox-off 0.3s forwards;
  -moz-animation: checkbox-off 0.3s forwards;
  -o-animation: checkbox-off 0.3s forwards;
  -ms-animation: checkbox-off 0.3s forwards;
  animation: checkbox-off 0.3s forwards;
}
.checkbox input[type=checkbox]:focus + .checkbox-material .check:after {
  opacity: 0.2;
}
.checkbox input[type=checkbox]:checked + .checkbox-material .check {
  background: $white-color;
  border-color: $primary-color;
}
.checkbox input[type=checkbox]:checked + .checkbox-material .check:before {
  color: $primary-color;
  box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  -webkit-animation: checkbox-on 0.3s forwards;
  -moz-animation: checkbox-on 0.3s forwards;
  -o-animation: checkbox-on 0.3s forwards;
  -ms-animation: checkbox-on 0.3s forwards;
  animation: checkbox-on 0.3s forwards;
}
.checkbox input[type=checkbox]:checked:disabled + .checkbox-material .check {
  background-color: $border-color;
  border-color: $border-color;
}
.checkbox input[type=checkbox]:checked:disabled + .checkbox-material .check:before {
  color: $black-lighter;
}
.checkbox.clicked input[type=checkbox]:checked + .checkbox-material:before {
  -webkit-animation: rippleOn 500ms;
  -moz-animation: rippleOn 500ms;
  -o-animation: rippleOn 500ms;
  -ms-animation: rippleOn 500ms;
  animation: rippleOn 500ms;
}
.checkbox.clicked input[type=checkbox]:checked + .checkbox-material .check:after {
  -webkit-animation: rippleOn 500ms forwards;
  -moz-animation: rippleOn 500ms forwards;
  -o-animation: rippleOn 500ms forwards;
  -ms-animation: rippleOn 500ms forwards;
  animation: rippleOn 500ms forwards;
}
.checkbox.clicked input[type=checkbox]:not(:checked) + .checkbox-material:before {
  -webkit-animation: rippleOff 500ms;
  -moz-animation: rippleOff 500ms;
  -o-animation: rippleOff 500ms;
  -ms-animation: rippleOff 500ms;
  animation: rippleOff 500ms;
}
.checkbox.clicked input[type=checkbox]:not(:checked) + .checkbox-material .check:after {
  -webkit-animation: rippleOff 500ms;
  -moz-animation: rippleOff 500ms;
  -o-animation: rippleOff 500ms;
  -ms-animation: rippleOff 500ms;
  animation: rippleOff 500ms;
}
.checkbox input[type=checkbox][disabled] ~ .checkbox-material .check {
  border-color: $border-color;
  background-color: $border-color;
}
.checkbox input[type=checkbox][disabled] + .checkbox-material .check:after {
  background-color: rgba(0,0,0, 0.87);
  transform: rotate(-45deg);
}

@keyframes checkbox-on {
  0% {
	box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
	box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
	box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@keyframes rippleOn {
  0% {
	opacity: 0;
  }
  50% {
	opacity: 0.2;
  }
  100% {
	opacity: 0;
  }
}
@keyframes rippleOff {
  0% {
	opacity: 0;
  }
  50% {
	opacity: 0.2;
  }
  100% {
	opacity: 0;
  }
}

// END STYLES for checkboxes


/*------------- CHECKBOX buttons Style2 --------------*/

.checkbox--style2 {
  &.checkbox .checkbox-material .check {
	border-color: $primary-color;
	background-color: $primary-color;
  }

  &.checkbox input[type="checkbox"]:checked + .checkbox-material .check::before {
	width: 10px;
	height: 10px;
	background-color: $black-lighter;
	box-shadow: none;
	animation: none;
	transform: none;
	margin: 0;
	top: 3px;
	left: 3px;
	border-radius: 2px;
  }

  &.checkbox input[type="checkbox"]:checked + .checkbox-material .check {
	background-color: $primary-color;
	border-color: $primary-color;
  }

  &.checkbox input[type="checkbox"]:checked:disabled + .checkbox-material .check {
	background-color: $black-lighter;
	border-color: $black-lighter;
  }

  &.checkbox input[type="checkbox"]:checked:disabled + .checkbox-material .check::before {
	background-color: $border-color;
  }
}


/*------------- CHECKBOX buttons Style3 --------------*/

.checkbox--style3 {

  &.checkbox input[type="checkbox"]:checked + .checkbox-material .check {
	background: $primary-color;
	border-color: $primary-color;
  }

  &.checkbox input[type="checkbox"]:checked + .checkbox-material .check::before {
	color: $black-lighter;
  }

  &.checkbox input[type="checkbox"]:checked:disabled + .checkbox-material .check {
	background-color: $border-color;
	border-color: $border-color;
  }

  &.checkbox input[type="checkbox"]:checked:disabled + .checkbox-material .check::before {
	color: $black-lighter;
  }
}


/*------------- CHECKBOX buttons Style4 --------------*/

.checkbox--style4 {
  &.checkbox input[type="checkbox"]:checked + .checkbox-material::after {
	opacity: 0;
  }

  &.checkbox .checkbox-material .check {
	border-color: $primary-color;
  }

  &.checkbox input[type="checkbox"]:checked + .checkbox-material .check {
	border-color: $primary-color;
	background-color: $black-lighter;
  }

  &.checkbox input[type="checkbox"]:checked + .checkbox-material .check::before {
	width: 10px;
	height: 10px;
	background-color: $primary-color;
	box-shadow: none;
	-webkit-animation: none;
	animation: none;
	-webkit-transform: none;
	transform: none;
	margin: 0;
	top: 3px;
	left: 3px;
	border-radius: 1px;
  }

  &.checkbox input[type="checkbox"]:checked:disabled + .checkbox-material .check::before {
	background-color: $border-color;
  }

  &.checkbox input[type="checkbox"]:checked:disabled + .checkbox-material .check {
	background-color: $black-lighter;
	border-color: $black-lighter;
  }
}


/*------------- CHECKBOX buttons Style5 --------------*/

.checkbox--style5 {
  &.checkbox .checkbox-material .check {
	background-color: $border-color;
	border-color: $border-color;
  }

  &.checkbox input[type="checkbox"]:checked + .checkbox-material .check {
	background-color: $black-lighter;
  }

  &.checkbox input[type="checkbox"]:checked + .checkbox-material .check::before {
	color: $primary-color;
  }

  &.checkbox input[type="checkbox"]:checked:disabled + .checkbox-material .check {
	border-color: $black-lighter;
	background-color: $border-color;
  }

  &.checkbox input[type="checkbox"]:checked:disabled + .checkbox-material .check::before {
	color: $black-lighter;
  }
}
