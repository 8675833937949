/*------------- #WINDOW-POPUP --------------*/


//For open and close (onClick) window-popup used class 'js-open-popup' in element <a class='js-open-popup ..."></a>

//For the correct operation, the parent element in which the window-popup is located must have a class "has-popup"

.window-popup {
  position: fixed;
  top: -2000px;
  left: 50%;
  display: block;
  transform: translate(-50%,0);
  transition: all 0.5s;
  z-index: 999999;
  margin-left: 0 !important;
  width: 1400px;
  max-width: calc(100% - 40px);
  border-radius: 30px;
  box-shadow: 80px 0 110px 5px rgba(0,0,0,.3);
  overflow: hidden;

  .mCustomScrollbar {
    max-height: calc(100vh - 200px);
  }

  .ps.ps--active-y > .ps__scrollbar-y-rail {
    z-index: 5;
    right: 20px !important;
    background-color: transparent;
  }

  .ps > .ps__scrollbar-y-rail > .ps__scrollbar-y {
    background-color: transparent;
    border-right: 4px dotted $primary-color;
    transition: all .3s ease;
  }

  .ps:hover > .ps__scrollbar-y-rail {
    opacity: 1;
  }

  .ps:hover > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
    background-color: #fbeb0b66;
  }
}
.window-popup .content {
  overflow: hidden;
  position: relative;
  z-index: 1;
  opacity: 1;
  transition: background-image .1s ease, all 600ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  background-image: url(../img/body-bg.png);
  background-color: $body-bg-color;
}
.window-popup.active {
  top: 100px;
}

.register-event {
  width: 680px;
}

body {
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(7, 8, 9, 0.5);
    opacity: 0;
    transition: opacity .3s ease;
    z-index: -999;
  }

  &.popup-active {

    .crumina-flying-balls [class*="col-"] {
      z-index: unset;
    }
  }

  &.body-overlay-active {

    &:before {
      position: fixed;
      width: 100%;
      height: 100%;
      opacity: 1;
      z-index: 99999;
    }
  }
}

.popup-close {
  position: fixed;
  top: 40px;
  right: 40px;
  z-index: 999;

  &:hover {
    .woox-icon {
      fill: $primary-color;
    }
  }

  .woox-icon {
    width: 40px;
    height: 40px;
    fill: $icon-color;
  }
}


.crumina-grid {

  .ps.ps--active-y > .ps__scrollbar-y-rail {
    z-index: 5;
    right: 20px !important;
    background-color: transparent;
  }

  .ps > .ps__scrollbar-y-rail > .ps__scrollbar-y {
    background-color: transparent;
    border-right: 4px dotted $primary-color;
    transition: all .3s ease;
    top: 0 !important;
  }

  .ps:hover > .ps__scrollbar-y-rail {
    opacity: 1;
  }

  .ps:hover > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
    background-color: #fbeb0b66;
  }

  .ps.ps--active-x > .ps__scrollbar-x-rail {
    background-color: transparent;
    z-index: 5;
  }

  .ps > .ps__scrollbar-x-rail:active > .ps__scrollbar-x,
  .ps > .ps__scrollbar-x-rail:hover > .ps__scrollbar-x {
    height: 6px;
  }

  .ps > .ps__scrollbar-x-rail > .ps__scrollbar-x {
    background-color: transparent;
    border-bottom: 4px dotted $primary-color;
    transition: all .3s ease;
    left: 0 !important;
  }

  .ps:hover > .ps__scrollbar-x-rail {
    opacity: 1;
  }

  .ps:hover > .ps__scrollbar-x-rail:hover > .ps__scrollbar-x {
    background-color: #fbeb0b66;
  }
}

/*================= Responsive Mode ============*/

@media (max-width: 991px) {
  .popup-close .woox-icon {
    width: 20px;
    height: 20px;
  }

  .author-details .popup-close {
    top: 20px;
    right: auto;
    left: 20px;
  }
}

@media (max-width: 460px) {
  .window-popup.active {
    top: 150px;
  }
}