/*------------- #TABLES --------------*/


table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 80px 0 110px 5px rgba(0, 0, 0, .3);
  overflow: hidden;
  border: none;
  text-align: left;
}

table * {
  transition: all .3s ease;
}

table > tbody > tr {
  &:hover {
    box-shadow: 15px 0 40px 5px rgba(19, 20, 24, .2);

    & > td {
      color: $white-color;
      background-color: #16171c;
      border-color: #16171c;
    }
  }
}

table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
  padding: 28px;
  line-height: 1.42857143;
  vertical-align: middle;
  border-top: 1px solid $table-border-color;
  background-color: #121418;
}

table > tfoot > tr > th,
table > tfoot > tr > td {
  padding: 60px 28px;
}
table > tbody > tr > td:last-child {
  border-right: none;
}
table > thead > tr > th {
  font-size: 20px;
  vertical-align: middle;
  color: $white-color;
  font-weight: $h-font-weight;
  background-color: #0f1114;
}
table > tbody > tr:first-child > td {
  border-top: none;
}
table > caption + thead > tr:first-child > th,
table > colgroup + thead > tr:first-child > th,
table > thead:first-child > tr:first-child > th,
table > caption + thead > tr:first-child > td,
table > colgroup + thead > tr:first-child > td,
table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

table > tbody + tbody {
  border-top: 2px solid $table-border-color;
}
table table {
  background-color: #fff;
}

table col[class*="col-"] {
  position: static;
  display:table-column;
  float: none;
}
table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  display:table-cell;
  float: none;
}
table > thead > tr > td.active,
table > tbody > tr > td.active,
table > tfoot > tr > td.active,
table > thead > tr > th.active,
table > tbody > tr > th.active,
table > tfoot > tr > th.active,
table > thead > tr.active > td,
table > tbody > tr.active > td,
table > tfoot > tr.active > td,
table > thead > tr.active > th,
table > tbody > tr.active > th,
table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}

table > thead > tr > td.success,
table > tbody > tr > td.success,
table > tfoot > tr > td.success,
table > thead > tr > th.success,
table > tbody > tr > th.success,
table > tfoot > tr > th.success,
table > thead > tr.success > td,
table > tbody > tr.success > td,
table > tfoot > tr.success > td,
table > thead > tr.success > th,
table > tbody > tr.success > th,
table > tfoot > tr.success > th {
  background-color: #dff0d8;
}

table > thead > tr > td.info,
table > tbody > tr > td.info,
table > tfoot > tr > td.info,
table > thead > tr > th.info,
table > tbody > tr > th.info,
table > tfoot > tr > th.info,
table > thead > tr.info > td,
table > tbody > tr.info > td,
table > tfoot > tr.info > td,
table > thead > tr.info > th,
table > tbody > tr.info > th,
table > tfoot > tr.info > th {
  background-color: #d9edf7;
}

table > thead > tr > td.warning,
table > tbody > tr > td.warning,
table > tfoot > tr > td.warning,
table > thead > tr > th.warning,
table > tbody > tr > th.warning,
table > tfoot > tr > th.warning,
table > thead > tr.warning > td,
table > tbody > tr.warning > td,
table > tfoot > tr.warning > td,
table > thead > tr.warning > th,
table > tbody > tr.warning > th,
table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}

table > thead > tr > td.danger,
table > tbody > tr > td.danger,
table > tfoot > tr > td.danger,
table > thead > tr > th.danger,
table > tbody > tr > th.danger,
table > tfoot > tr > th.danger,
table > thead > tr.danger > td,
table > tbody > tr.danger > td,
table > tfoot > tr.danger > td,
table > thead > tr.danger > th,
table > tbody > tr.danger > th,
table > tfoot > tr.danger > th {
  background-color: #f2dede;
}


/*------------- #TABLES Style1--------------*/

.table--style1 {
  & > tbody > tr > td:first-child {
	font-weight: $h-font-weight;
  }

	& > tbody > tr:hover {
	  & > td:first-child {
		color: $primary-color;
	  }
	}
}

/*------------- #TABLES Style2--------------*/

.table--style2 {

  & > thead > tr > th {
    font-size: 12px;
    text-transform: uppercase;
  }

  & > tbody > tr > td,
  & > tfoot > tr > td {
    border-top-color: transparent;
	border-right-color: transparent;
	border-bottom-color: transparent;
	border-left-color: transparent;
  }

  & > tbody > tr:nth-child(odd) {
    & > td {
      background-color: $bg-accordion-faqs;
    }
  }

  & > tbody:hover > tr {
	filter: blur(2px);
  }

  & > tbody > tr:hover {
	filter: blur(0);

    & > td {
      background-color: $bg-accordion-faqs;
      color: $link-color;

      &:first-child {
        color: $icon-color;
      }
    }
  }

  & > tbody > tr > td,
  & > thead > tr > th:first-child {
    color: $icon-color;

    &.c-brown {
      &:before {
        background-color: $brown;
      }
    }

    &.c-blue {
      &:before {
        background-color: $blue;
      }
    }

    &.c-blue-light {
      &:before {
        background-color: $blue-light;
      }
    }

	&.c-border-color {
	  &:before {
		background-color: $border-color;
	  }
	}

	&.c-blue-lighter {
	  &:before {
		background-color: $blue-lighter;
	  }
	}

	&.c-primary {
	  &:before {
		background-color: $primary-color;
	  }
	}
  }

  & > tbody > tr > td {
    color: $link-color;
  }

  & > tbody > tr > td:first-child {
    position: relative;

    &:before {
      content: '';
      display: block;
      height: 100%;
      width: 4px;
      position: absolute;
      top: 0;
      left: 0;
      transition: all .3s ease;
    }
  }
}

/*------------- #TABLES Style3--------------*/

.table--style3 {
  border-collapse: unset;

  & > thead > tr > th {
    font-size: 18px;
  }

  & > tbody > tr:hover {
	td {
	  background-color: #131519;
	  color: $body-font-color;
	}
  }

  & > tbody > tr > td {
    position: relative;
    color: $body-font-color;
	background-color: #131519;

	&.c-brown {
	  &:before {
		background-color: $brown;
	  }
	}

	&.c-blue {
	  &:before {
		background-color: $blue;
	  }
	}

	&.c-blue-light {
	  &:before {
		background-color: $blue-light;
	  }
	}

	&.c-border-color {
	  &:before {
		background-color: $border-color;
	  }
	}

	&.c-blue-lighter {
	  &:before {
		background-color: $blue-lighter;
	  }
	}

	&.c-primary {
	  &:before {
		background-color: $primary-color;
	  }
	}

    &:hover {
	  background-color: #16171c;
	  color: $link-color;

      &:before {
        opacity: 1;
      }
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 4px;
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0;
      transition: all .3s ease;
    }
  }
}


.scrollable-responsive-table {
  table {
	overflow-x: auto;
	overflow-y: hidden;
	box-shadow: none;
  }
}


/*================= Responsive Mode ============*/

@media (max-width: 799px) {
  table > thead > tr > th,
  table > tbody > tr > th,
  table > tfoot > tr > th,
  table > thead > tr > td,
  table > tbody > tr > td,
  table > tfoot > tr > td {
    padding: 15px;
  }

  table {
	border-radius: 10px;
  }

  table > thead > tr > th {
	font-size: 12px;
  }

  .table--style3 > thead > tr > th {
	font-size: 12px;
  }
}

@media (max-width: 480px) {
  table > thead > tr > th,
  table > tbody > tr > th,
  table > tfoot > tr > th,
  table > thead > tr > td,
  table > tbody > tr > td,
  table > tfoot > tr > td {
    padding: 5px;
  }

  table {
    font-size: 10px;
  }
}