/*------------- #FORMS --------------*/


input, textarea, select, .select2 {
  padding: 15px 30px;
  transition: all .3s ease;
  font-size: 16px;
  font-weight: $h-font-weight;
  margin-bottom: 20px;
  border-radius: 50px;
  background-color: $white-color;
  color: $input-color;
  border: 2px solid $body-font-color;
  width: 100%;
  outline: none;
  caret-color: $primary-color;

  &:focus {
    color: $icon-color;

    & + .woox-icon {
      fill: $primary-color;
      color: $primary-color;
    }
  }

  &.input--squared {
    border-radius: 8px;
    padding: 15px 30px;
  }

  &.input--dark {
    background-color: $input-dark-bg;
	color: $body-font-color;
    border: 2px solid #333744;

	&:focus {
	  background-color: $white-color;
	  color: $icon-color;
	}

    &.input--with-border {
      background-color: $secondary-color;
    }
  }

  &.input--with-border {
	border-color: $dark-light;

	&:focus {
	  border-color: $primary-color;
	}
  }
}

.woox-icon {
  transition: all .3s ease;
}

::-webkit-input-placeholder {color:$body-font-color;}
::-moz-placeholder          {color:$body-font-color;}/* Firefox 19+ */
:-moz-placeholder           {color:$body-font-color;}/* Firefox 18- */
:-ms-input-placeholder      {color:$body-font-color;}

label {
  display: block;
  font-size: 14px;
  color: $white-color;
  font-weight: $h-font-weight;
  margin: 20px 0 20px 35px;

  span {
    color: $red-light;
    margin-left: 3px;
  }
}


/*------------- Input with Button inline --------------*/

.input-with-btn-inline {
  position: relative;

  input {
	margin-bottom: 0;
	font-size: 14px;
	padding: 17px 40px;

	&:focus {
	  color: $icon-color;
	  box-shadow: 20px 0 40px 5px rgba(0, 0, 0, 0.7);
	}
  }

  .btn,
  button {
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
  }
}

/*------------- Subscribe FORMS --------------*/


.subscribe-form {
  padding: 60px;
  border-radius: 20px;
  text-align: center;
}

.subscribe--primary {
  background-color: $primary-color;

  input {
	color: $icon-color;

	&:focus {
	  color: $primary-color;
	}
  }
}

.subscribe--dark {
  background-color: $testimonial-bg;
  box-shadow: 40px 0 90px 5px rgba(0, 0, 0, 0.5);

  input {
    padding: 20px 40px;
  }
}


/*------------- FORMS search --------------*/

.form--search {
  input {
    padding: 25px 30px;
  }
}

.form--search-transparent {
  position: relative;

  input {
    padding: 0 0 20px 0;
    margin-bottom: 0;
    background-color: transparent;
    border-color: transparent;
    border-bottom: 4px solid $border-color;
    border-radius: 0;
    color: $icon-color;

    &:focus {
      border-bottom-color: $primary-color;
      color: $white-color;
    }
  }
}

/*------------- Login FORMS --------------*/


.login-form {
  padding: 120px;
  border-radius: 20px;
  text-align: center;

  .crumina-heading {
    margin-bottom: 60px;
  }

  .checkbox label {
    font-size: 16px;
  }

  .checkbox {
    margin: 1.4em 0;
    text-align: left;
  }

  .btn + .btn {
    margin-left: 0;
    margin-top: 20px;
  }

  .forgot-block {
    margin: 60px 0;
  }

  .input-label {
    margin: 20px 0;
    font-weight: $weight-black;
    font-size: 14px;
    color: $heading-color;
    display: block;
    text-align: left;

    abbr {
      color: $red;
    }
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  & > *:first-child {
    margin-top: 0;
  }
}

.register-form {
  padding: 120px;
  border-radius: 20px;

  .crumina-heading {
    margin-bottom: 60px;
  }

  .checkbox {
    margin: 60px 0;
  }
}

.form--dark {
  background-color: $testimonial-bg;

  .input-label {
	color: $white-color;
  }
}

.input-with-icon {
  position: relative;

  .woox-icon {
    position: absolute;
    left: 30px;
    top: 50%;
    height: 25px;
    width: 25px;
    font-size: 20px;
    fill: $link-color;
    color: $link-color;
    z-index: 1;
    transition: all .3s ease;
    transform: translate(0, -50%);
  }

  input, textarea, select {
    padding-left: 80px;
    margin-bottom: 0;

    &:focus + .woox-icon {
      fill: $primary-color;
    }

	&.input--dark {
	  & + .woox-icon {
        color: $border-color;
        fill: $border-color;
	  }

	  &:focus + .woox-icon {
		fill: $primary-color;
		color: $primary-color;
	  }
	}
  }

  &.input-icon--right {

    .woox-icon {
      left: auto;
      right: 30px;
    }

    input, textarea, select {
      padding-left: 30px;
      padding-right: 80px;
    }
  }
}

.forgot-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.6em 0;
  width: 100%;

  .checkbox {
    margin: 0;
  }

  a {
    font-weight: 700;
  }
}

/*------------- Styles for inputs with label (material) --------------*/

/*https://www.creative-tim.com/product/material-kit#*/

.form-group.is-focused .checkbox label {
  color: rgba(0,0,0, 0.26);
}

.form-group label.control-label {
  line-height: 1.07143;
  margin: 0;
  font-size: 16px;
  font-weight: $weight-black;
  color: $body-font-color;
}

.form-group.label-floating label.control-label, .form-group.label-placeholder label.control-label {
  top: 25px;
  left: 30px;
}
.form-group.label-static label.control-label, .form-group.label-floating.is-focused label.control-label, .form-group.label-floating:not(.is-empty) label.control-label {
  top: 7px;
  font-size: 11px;
  line-height: 1.07143;
}
.form-group.label-floating input.form-control:-webkit-autofill ~ label.control-label label.control-label {
  top: -28px;
  left: 0;
  font-size: 11px;
  line-height: 1.07143;
}

input.form-control {
  padding: 17px 30px;;
}

select.form-control {
  border: 0;
  box-shadow: none;
  border-radius: 0;
}
.form-group.is-focused select.form-control {
  box-shadow: none;
  border-color: #D2D2D2;
}
select.form-control[multiple], .form-group.is-focused select.form-control[multiple] {
  height: 85px;
}

.input-group-btn .btn {
  margin: 0 0 7px 0;
}

.form-group.form-group-sm .input-group-btn .btn {
  margin: 0 0 3px 0;
}
.form-group.form-group-lg .input-group-btn .btn {
  margin: 0 0 9px 0;
}

.form-group input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.form-group {
  position: relative;
}

.form-group.label-static label.control-label, .form-group.label-placeholder label.control-label, .form-group.label-floating label.control-label {
  position: absolute;
  pointer-events: none;
  transition: 0.3s ease all;
}

.form-group.label-floating label.control-label {
  will-change: left, top, contents;
}

.form-group.label-placeholder:not(.is-empty) label.control-label {
  display: none;
}

.form-group select ~ .material-input:after {
  display: none;
}


/*------------- #SELECT2 Customization --------------*/


select, .select2 {
  border-color: #333744;

  &:disabled {
    background-color: $bg-accordion-grey;
    color: $border-color;

    & + .select2-container--default .select2-selection--single .select2-selection__arrow b {
      border-bottom-color: $body-font-color;
      border-right-color: $body-font-color;
    }

    & + .select2-container--default.select2-container--disabled .select2-selection--single {
      background-color: transparent;
    }

    & + .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: $border-color;
    }
  }
}

.select2 {
  padding: 14px 30px;
}

.select2-container {
  margin-bottom: 10px;
}

.select2-container--default .select2-selection--single {
  border-color: transparent;
  background-color: transparent;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding: 0;
  color: $input-color;
}

.select2-container.select2-container--open .select2-selection--single .select2-selection__rendered {
  color: $icon-color;
  transition: all .3s ease;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 40px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: block;
  border-bottom: 3px solid $body-font-color;
  border-right: 3px solid $body-font-color;
  height: 10px;
  pointer-events: none;
  position: absolute;
  transform-origin: 66% 66%;
  transform: rotate(45deg);
  transition: all 0.2s ease-in-out;
  width: 10px;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  transform: rotate(-135deg);
  border-bottom-color: $primary-color;
  border-right-color: $primary-color;
}

.select2.select2-container--disabled {
  background-color: $bg-accordion-grey;
}

.select2-dropdown {
  box-shadow: 15px 0 40px 5px rgba(61,67,79,.08);
  padding: 20px 10px;
}

.select2-results__option {
  border-radius: 5px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #f8fafc;
  color: $primary-color;
}


/*------------- #Woox-select Dark --------------*/

.woox--select--dark {
  & + .select2 {
    background-color: $bg-accordion-grey;
  }

  & + .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-bottom-color: $body-font-color;
    border-right-color: $body-font-color;
  }

  & + .select2-container .select2-selection--single .select2-selection__rendered {
    color: $icon-color;
  }

  & + .select2-container.select2-container--open .select2-selection--single .select2-selection__rendered {
    color: $link-color;
    transition: all .3s ease;
  }

  & + .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-bottom-color: #ffba00;
    border-right-color: #ffba00;
  }
}

.woox--select--dark-dropdown {
  background-color: $bg-accordion-grey;

  .select2-results__option--highlighted[aria-selected],
  .select2-results__option[aria-selected="true"] {
    background-color: #22262c;
  }
}

/*------------- #Woox-select style-squared --------------*/

.woox--select-squared {
  border-radius: 8px;
  padding: 14px 30px;

  & + .select2 {
    border-radius: 8px;
    z-index: 5;
    position: relative;
    transition: none;

    &.select2-container--open {
      border-radius: 8px 8px 0 0;
      box-shadow: 15px 0 40px 5px rgba(61,67,79,.08);
    }
  }

  &:disabled {
    & + .select2-container--default .select2-selection--single .select2-selection__arrow:after {
      background-color: #2a2f36;
    }
  }

  & + .select2-container--default .select2-selection--single .select2-selection__arrow {
    right: 35px;
  }

  & + .select2-container--default .select2-selection--single .select2-selection__arrow:after {
    top: -4px;
    left: -20px;
    border-bottom: 0;
    border-right: 0;
    content: '';
    display: block;
    height: 20px;
    pointer-events: none;
    position: absolute;
    transition: all .2s ease-in-out;
    width: 1px;
    opacity: 1;
    background-color: #a0a9ba;
  }

  & + .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow:after {
    background-color: $primary-color;
  }

  &.woox--select--dark {
    & + .select2-container--default .select2-selection--single .select2-selection__arrow::after {
      background-color: $border-color;
    }

    & + .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow:after {
      background-color: $primary-color;
    }
  }
}

.woox--select-squared-dropdown {
  border-radius: 0 0 8px 8px;
  margin-top: -10px;

  .select2-results__option::after {
    border-bottom-color: $primary-color;
    border-right-color: $primary-color;
  }
}

.woox--select-squared-dropdown-dark {
  border-radius: 0 0 8px 8px;
  margin-top: -10px;
  background-color: $bg-accordion-faqs;

  .select2-results__option::after {
    border-bottom-color: $primary-color;
    border-right-color: $primary-color;
  }

  .select2-results__option--highlighted[aria-selected],
  .select2-results__option[aria-selected="true"] {
    background-color: $bg-accordion-grey;
  }
}


/*------------- Language Switcher --------------*/

.language-switcher {
  width: auto;
  min-width: 150px;

  & + .select2 {
	padding: 14px 50px 14px 30px;
	border: 2px solid $body-font-color;
    background-color: transparent;
	font-size: 14px;
    transition: none;
    width: auto;
    min-width: 150px;
  }

  & + .select2-container {
	margin-bottom: 0;

	&.select2-container--open {
	  border-radius: 30px 30px 0 0;
	  border-color: transparent;
	  background-color: $bg-accordion;
	}
  }
}

.language-switcher-dropdown {
  border-radius: 0 0 30px 30px;
  background-color: $bg-accordion;
  top: 0;
  padding: 0 20px 20px 20px;

  .select2-results__option {
    padding: 10px 0;
    border-top: 1px solid $hr-color;
    border-radius: 0;

    &:last-child {
      padding-bottom: 0;
    }

    span {
      display: flex;
      align-items: center;

      .woox-icon {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        margin-right: 10px;
      }
    }

    &:after {
      display: none;
    }
  }

  .select2-results__option[aria-selected="true"],
  .select2-results__option--highlighted[aria-selected] {
    background-color: transparent;
    color: $primary-color;
  }
}


/*------------- Contact FORMS --------------*/

.contact-form {
  box-shadow: none;
  padding: 0;
  margin-top: 50px;

  .select2, input, select, textarea {
	margin-bottom: 40px;
  }

  button {
    margin-bottom: 0;
  }
}



/*================= Responsive Mode ============*/


@media (max-width: 1300px) {
  .subscribe-form {
    padding: 40px;
  }
}

@media (max-width: 1200px) {
  .language-switcher + .select2 {
    padding: 5px 50px 5px 30px;
  }

  .contact-form input {
    margin-bottom: 30px;
  }
}

@media (max-width: 1024px) {
  .login-form,
  .register-form {
    padding: 40px;
  }

  .contact-form {
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .input-with-btn-inline {
    text-align: center;

    .btn,
    button {
      position: relative;
      right: auto;
      top: auto;
      height: auto;
      margin-top: 20px;
    }
  }

  .form-group label.control-label {
    font-size: 12px;
  }

  .login-form {
    padding: 20px;
  }

  .select2, input, select, textarea {
	font-size: 12px;
	padding: 10px 20px;
  }

  input.form-control {
    padding: 12px 18px;
  }

  label {
    margin: 20px 0 20px 20px;
  }

  textarea.input--squared {
    padding: 12px 18px;
  }

  .form-group.label-floating label.control-label,
  .form-group.label-placeholder label.control-label {
    top: 17px;
    left: 23px;
  }

  .form-group.label-floating.is-focused label.control-label,
  .form-group.label-floating:not(.is-empty) label.control-label,
  .form-group.label-static label.control-label {
    top: 5px;
    left: 18px;
  }

  .contact-form input {
    margin-bottom: 20px;
  }

  .leave-reply label {
    margin: 5px 0 5px 0;
  }

  .form--search input {
    padding: 13px 30px 13px 80px;
  }

  .login-form .forgot-block {
    margin: 30px 0;
  }

  .login-form .checkbox label,
  .forgot-block a {
    font-size: 14px;
  }
}

@media (max-width: 420px) {
  .forgot-block {
    flex-direction: column;
	align-items: flex-start;

    & > * + * {
      margin-top: 20px;
    }
  }

  textarea {
    height: 150px !important;
  }

  .subscribe--dark input {
    padding: 20px 30px;
  }
}